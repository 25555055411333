{
    "name": "dashboard",
    "version": "4.9.3",
    "workspaces": [
        "functions",
        "shared_zod",
        "shared_models"
    ],
    "scripts": {
        "ng": "NODE_OPTIONS=--max-old-space-size=2048 ng",
        "start": "./getLocals.sh && ng serve",
        "start:limit": "npm run ng && ./getLocals.sh && ng serve",
        "start:phone": "./getLocals.sh && ng serve --host 0.0.0.0",
        "s": "ng serve",
        "start:hmr": "./getLocals.sh && ng serve --hmr",
        "doc": "compodoc -p src/tsconfig.app.json -s -d doc -n 'Airwallet Documentation' --customFavicon src/favicon.png --hideGenerator",
        "build:test": "ng build --configuration development",
        "build:stag": "ng build --configuration staging",
        "build:prod": "ng build --configuration production",
        "translate": "./getLocals.sh",
        "lint": "ng lint",
        "lint-fix": "ng lint --fix"
    },
    "private": true,
    "dependencies": {
        "@angular/animations": "18.2.6",
        "@angular/cdk": "18.2.6",
        "@angular/common": "18.2.6",
        "@angular/compiler": "18.2.6",
        "@angular/core": "18.2.6",
        "@angular/fire": "18.0.1",
        "@angular/forms": "18.2.6",
        "@angular/google-maps": "18.2.6",
        "@angular/localize": "18.2.6",
        "@angular/material": "18.2.6",
        "@angular/platform-browser": "18.2.6",
        "@angular/platform-browser-dynamic": "18.2.6",
        "@angular/router": "18.2.6",
        "@fullcalendar/angular": "6.1.15",
        "@fullcalendar/core": "6.1.15",
        "@fullcalendar/interaction": "6.1.15",
        "@fullcalendar/timegrid": "6.1.15",
        "@ng-bootstrap/ng-bootstrap": "17.0.0",
        "@ng-select/ng-select": "13.2.0",
        "@ngx-translate/core": "14.0.0",
        "@ngx-translate/http-loader": "7.0.0",
        "@popperjs/core": "2.11.8",
        "@stripe/stripe-js": "1.19.1",
        "@types/google-libphonenumber": "7.4.30",
        "@types/swagger-ui-dist": "3.30.3",
        "@types/vanillajs-datepicker": "1.3.0",
        "angularx-qrcode": "18.0.2",
        "bootstrap": "5.3.3",
        "chart.js": "4.4.3",
        "copy-to-clipboard": "3.3.3",
        "country-locale-map": "1.9.4",
        "crisp-sdk-web": "1.0.25",
        "dayjs": "1.11.11",
        "dotenv": "16.4.5",
        "email-validator": "2.0.4",
        "file-saver": "2.0.5",
        "firebase": "10.12.2",
        "google-libphonenumber": "3.2.33",
        "js-base64": "3.7.7",
        "js-yaml": "4.1.0",
        "lottie-web": "5.9.6",
        "mixpanel-browser": "2.58.0",
        "moment": "2.30.1",
        "moment-timezone": "0.5.45",
        "n-readlines": "1.0.1",
        "ng2-charts": "5.0.4",
        "ngx-lottie": "11.0.2",
        "ngx-toastr": "16.2.0",
        "object-hash": "3.0.0",
        "path": "0.12.7",
        "random-string": "0.2.0",
        "rxjs": "7.8.0",
        "swagger-ui-dist": "5.9.1",
        "ts-json-schema-generator": "1.5.1",
        "valid-url": "1.0.9",
        "zod": "3.22.4"
    },
    "devDependencies": {
        "@angular/build": "18.2.6",
        "@angular/cli": "18.2.6",
        "@angular/compiler-cli": "18.2.6",
        "@angular/language-service": "18.2.6",
        "@compodoc/compodoc": "1.1.25",
        "@types/chart.js": "2.9.41",
        "@types/file-saver": "2.0.7",
        "@types/js-yaml": "4.0.9",
        "@types/lodash": "^4.17.7",
        "@types/mixpanel-browser": "^2.51.0",
        "@types/node": "18.19.34",
        "@types/object-hash": "2.2.1",
        "@types/random-string": "0.0.28",
        "@types/ssri": "7.1.5",
        "@types/valid-url": "1.0.7",
        "angular-eslint": "18.1.0",
        "core-js": "3.25.1",
        "eslint": "9.6.0",
        "firebase-tools": "13.11.4",
        "prettier": "^3.3.3",
        "stripe": "9.1.0",
        "typescript": "5.4.5",
        "typescript-eslint": "8.0.0-alpha.38",
        "vanillajs-datepicker": "1.3.4",
        "yaml-loader": "0.8.1",
        "zone.js": "0.14.10"
    }
}
